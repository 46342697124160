<template>
  <div>
    <b-container fluid>
      <b-row style="margin-top:10px;">
        <b-col cols="12" class="pageTitleRwd">
            <span class="pageTitle">{{ $t("fuQiangXin.title") }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-breadcrumb :items="breadcrumbItems" class="bread-crumb" style="font-size: 15px"></b-breadcrumb>
      </b-row>
      <b-row>
        <div class="table-title">
          <div>
            {{ $t("fuQiangXin.chartTitle") }}
          </div>
          <div>
             {{ getTimeNow }}
          </div>
        </div>
      </b-row>
      <!-- table -->
      <b-row class="overflow-scroll px-3">
        <b-table :items="handledData" :fields="tableFields" :responsive="true" head-variant="dark" hover selected-variant="danger" table-class="table-class" :tbody-tr-class="rowClass" style="font-size: .5rem" ></b-table>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'fuqiangxin',
  data () {
    return {
      breadcrumbItems: [
        {
          text: '首頁',
          to: {
            name: 'realtime'
          }
        },
        {
          text: '發電資訊彙整',
          active: true
        }
      ],
      tableFields: ['名稱', '設置容量(kWp)', '日照亮(kW/㎡)', '模組溫度(C)', '直流功率(kW)', '交流功率(kW)', '今日發電量(kW/h)', '本月累積發電量(kW/h)', '平均每日每 kW 發電量', 'INV 效率(%)', 'RA(%)', 'PR(%)'],
      tableItems: [
        [
          'FCS1期(東)',
          491.4,
          115,
          24,
          29,
          28,
          508.74,
          '19,026.71',
          '2.04',
          98,
          51,
          52
        ],
        [
          'FCS1期(西)',
          492.75,
          115,
          24,
          32,
          32,
          555.16,
          '19,695.97',
          '2.1',
          99,
          57,
          57
        ],
        [
          '小計(1期)',
          984.15,
          '-',
          '-',
          '-',
          '-',
          '1,064',
          '38,723',
          '2.07',
          '-',
          '-',
          '-'
        ],
        [
          '小計售電金額(1期)',
          984.15,
          '-',
          '-',
          '-',
          '-',
          '$ 9,388',
          '$ 341,693',
          '-',
          '-',
          '-',
          '-'
        ],
        [
          'FCS2期',
          160.19,
          111,
          27,
          15,
          13,
          256.97,
          '8,454.87',
          '2.78',
          90,
          84,
          81
        ],
        [
          'FCS3期',
          499.14,
          115,
          27,
          50,
          46,
          831.42,
          '28,369.32',
          '2.99',
          91,
          87,
          85
        ],
        [
          'FCS4期',
          499.73,
          111,
          27,
          45,
          41,
          810.14,
          '26,096.48',
          '2.75',
          90,
          79,
          82
        ],
        [
          '小計(2~4期)',
          '1,159.06',
          '-',
          '-',
          '-',
          '-',
          '1,899',
          '62,921',
          '2.84',
          '-',
          '-',
          '-'
        ],
        [
          '小計售電金額(2~4期)',
          '1,159.06',
          '-',
          '-',
          '-',
          '-',
          '$ 8,862',
          '$ 293,707',
          '-',
          '-',
          '-',
          '-'
        ],
        [
          'FCS5期',
          493.24,
          115,
          24,
          43,
          39,
          766.12,
          '26,021.96',
          '2.78',
          91,
          76,
          78
        ],
        [
          '小計(5期)',
          '493.24',
          '-',
          '-',
          '-',
          '-',
          '766',
          '26,022',
          '2.78',
          '-',
          '-',
          '-'
        ],
        [
          '小計售電金額(5期)',
          '493.24',
          '-',
          '-',
          '-',
          '-',
          '$ 3,581',
          '$ 121,637',
          '-',
          '-',
          '-',
          '-'
        ],
        [
          'FCS6期',
          343.2,
          112,
          27,
          30,
          28,
          532.34,
          '16,827.51',
          '2.58',
          91,
          78,
          79
        ],
        [
          '小計(6期)',
          '343.20',
          '-',
          '-',
          '-',
          '-',
          '532',
          '16,828',
          '2.58',
          '-',
          '-',
          '-'
        ],
        [
          '小計售電金額(6期)',
          '343.20',
          '-',
          '-',
          '-',
          '-',
          '$ 2,441',
          '$ 77,171',
          '-',
          '-',
          '-',
          '-'
        ],
        [
          '合計(1~6期)',
          '2979.65',
          '-',
          '-',
          '-',
          '-',
          '4,261',
          '144,493',
          '2.57',
          '-',
          '-',
          '-'
        ],
        [
          '合計售電金額(1~6期)',
          '2979.65',
          '-',
          '-',
          '-',
          '-',
          '$ 24,273',
          '$ 834,208',
          '-',
          '-',
          '-',
          '-'
        ]
      ],
      handledData: []
    }
  },
  methods: {
    handleData () {
      let k
      for (k = 0; k < this.tableItems.length; k++) {
        const temp = {}
        let i
        for (i = 0; i < this.tableFields.length; i++) {
          temp[this.tableFields[i]] = null
        }
        temp['名稱'] = this.tableItems[k][0]
        temp['設置容量(kWp)'] = this.tableItems[k][1]
        temp['日照亮(kW/㎡)'] = this.tableItems[k][2]
        temp['模組溫度(C)'] = this.tableItems[k][3]
        temp['直流功率(kW)'] = this.tableItems[k][4]
        temp['交流功率(kW)'] = this.tableItems[k][5]
        temp['今日發電量(kW/h)'] = this.tableItems[k][6]
        temp['本月累積發電量(kW/h)'] = this.tableItems[k][7]
        temp['平均每日每 kW 發電量'] = this.tableItems[k][8]
        temp['INV 效率(%)'] = this.tableItems[k][9]
        temp['RA(%)'] = this.tableItems[k][10]
        temp['PR(%)'] = this.tableItems[k][11]
        this.handledData.push(temp)
      }
    },
    rowClass (item) {
      let regex = /小計/gm
      let regex2 = /合計/gm
      if (regex.test(item['名稱'])) {
        return 'table-success'
      } else if (regex2.test(item['名稱'])) {
        return 'table-warning'
      }
    }
  },
  computed: {
    ...mapGetters([
      'getTimenow'
    ])
  },
  created () {
    this.handleData()
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/FuQiangXin.scss'
</style>
